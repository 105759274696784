var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-8",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('div',{staticClass:"text-h5"},[_vm._v("All Users")])])],1),_c('v-row',{staticStyle:{"align-items":"center"}},[_c('v-col',{staticClass:"mb-1"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary",attrs:{"large":"","rounded-lg":"","dark":"","to":"/admin/create"}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("../assets/plus.svg"),"alt":"plus Icon"}}),_c('div',{staticClass:"pl-1"},[_vm._v("Create User")])])]}}])})],1),_c('v-col',{staticClass:"d-flex",staticStyle:{"align-items":"center"},attrs:{"cols":"8","sm":"8","md":"8"}},[_c('v-text-field',{attrs:{"label":"Search","prepend-inner-icon":"mdi-magnify","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"purple-button-light lightPurple purple-button-light-height-large mx-2",attrs:{"rounded":"","disabled":_vm.selected.length == 0}},[_c('img',{attrs:{"src":require("../assets/Delete.svg"),"alt":"Edit Icon"}}),_vm._v("Delete")]),_c('v-btn',{staticClass:"purple-button-light lightPurple purple-button-light-height-large mx-2",attrs:{"rounded":""}},[_c('v-icon',[_vm._v("mdi-export")]),_vm._v("Export")],1)],1)],1),_c('v-data-table',{staticClass:"usertable",attrs:{"headers":_vm.headers,"items":_vm.users,"show-select":"","search":_vm.search,"single-select":_vm.singleSelect,"item-key":"id","options":_vm.options,"footer-props":{
      itemsPerPageOptions: [10, 25, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.first`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.first + " " + item.last)+" ")]}},{key:`item.role`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.role.name)+" ")]}},{key:`item.school`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.school_name && item.school_name != '' ? item.school_name.join(',') : "N/A")+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row"},[_c('img',{staticClass:"cursor",attrs:{"width":"20px","height":"36","src":require("../assets/editsquare.svg")},on:{"click":function($event){return _vm.redirectToEdit(item)}}}),_c('img',{staticClass:"cursor",attrs:{"width":"36px","height":"36","src":require("../assets/userdelete.svg")},on:{"click":() => {
              _vm.deleteDialog = true;
              _vm.selected = item;
            }}})])]}},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getDate(item.created_at))+" ")]}},{key:`item.updated_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getDate(item.created_at))+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"max-width":"366px","persistent":""},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',{attrs:{"fluid":""}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-container'),_c('v-avatar',{attrs:{"color":"secondary","size":"90"}},[_c('v-icon',{attrs:{"size":"65"}},[_vm._v("mdi-trash-can-outline")])],1),_c('p',{staticClass:"text-h5 pt-6 pb-0"},[_vm._v("Delete Admin User")]),_c('p',{staticClass:"text-disabled grey--text text-subtitle-1 pt-3",attrs:{"color":"rgba(0, 0, 0, 0.6)","disabled":""}},[_vm._v(" This action will permanently delete the item . This cannot be undone ")]),_c('div',{staticClass:"d-flex justify-space-between pt-4 pb-2",attrs:{"fluid":""}},[_c('v-btn',{staticClass:"secondary black--text",attrs:{"depressed":"","large":"","width":"157px","rounded":""},on:{"click":() => {
                  _vm.deleteDialog = false;
                  _vm.formbtnBool = false;
                }}},[_vm._v("CANCEL")]),_c('v-btn',{staticClass:"black white--text",attrs:{"depressed":"","large":"","width":"157px","rounded":""},on:{"click":function($event){return _vm.deleteUser()}}},[_vm._v("DELETE")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }