<template>
  <v-container fluid class="pa-8 pt-0 secondary">
    <!-- assessment user table row with filter btn and color code -->
    <v-row justify="space-between" class="my-8 mt-0 mb-2" v-if="showUsers">
      <v-col cols="4" sm="4" md="4">
        <div class="text-h5 ml-0">Recommendation Panel</div>
      </v-col>
    </v-row>

    <!-- Assessment row and fiter and export btn -->

    <v-row justify="space-between" class="my-0" v-if="showUsers">
      <v-col cols="6" sm="6" md="" class="ma-0 pa-0 f-flex align-center">
        <div class="m-tab">
          <v-tabs
            background-color="#0000000D"
            class="ml-2 d-flex space-evenly"
            height="38"
            hide-slider
          >
            <v-tab
              active-class=" white ma-1 black--text"
              class="rounded"
              v-on:click="onClickRecommendation('TEACHER')"
            >
              TEACHER
            </v-tab>
            <v-tab
              active-class=" white ma-1 black--text"
              class="rounded"
              v-on:click="onClickRecommendation('JOB_SEEKER')"
            >
              JOB SEEKER
            </v-tab>
          </v-tabs>
        </div>
      </v-col>
      <v-col cols="8" sm="8" class=" ma-0 pa-0 pe-4 d-flex justify-end">
        <div>
          <v-text-field
            v-model="search"
            label="Search"
            class="pt-0"
            clearable
          >
            <!-- The slot "prepend" is used to add content before the input -->
            <template #prepend>
              <img src="../assets/usearch.svg" alt="Edit Icon" />
            </template>
          </v-text-field>
        </div>
      </v-col>

    </v-row>

    <!--diolog for delete academic and professional array-->
    <v-dialog v-model="statusRecomm" max-width="366px" persistent>
      <v-card fluid>
        <v-container fluid class="pa-0">
          <v-card-text class="text-center">
            <v-avatar color="#db44371f" size="90"
              ><v-icon size="65" color="#32CD32"
                >mdi-check-circle-outline</v-icon
              ></v-avatar
            >

            <p class="text-h5 pt-4 pb-0">
              You are "{{ isAgreeText.toLocaleLowerCase() }}" with ai recommendation?
            </p>
           
            <div class="d-flex justify-space-between" fluid>
              <v-btn
                class="black--text"
                color="#0000001a"
                large
                width="157px"
                rounded
                @click="statusRecomm = false"
                >CANCEL</v-btn
              >
              <v-btn
                class="primary"
                large
                width="157px"
                rounded
                @click="recommendedUpdate(isAgreeText)"
                >YES</v-btn
              >
            </div>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="demoRemarks" width="500">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-card-title class="lightPurple mb-4"> Demo Video Remarks </v-card-title>
          <v-card-text>
            <v-text-field
              outlined
              class="rounded-xl"
              v-model="name"
              label="Name*"
              required
              :disabled="true"
              :rules="[(v) => !!v || 'Demo Coordinator name is required']"
            ></v-text-field>
            <v-textarea
              outlined
              class="rounded-xl reason-input"
              v-model="remarks"
              label="Reasons*"
              required
              :rules="[(v) => !!v || 'Remarks is required']"
              rows="3"
            ></v-textarea>
            <v-row align="center" cols="12" class="ms-0">
              <div>
                <div class="me-8 pop-recommended">Recommendation</div>
              </div>
              <div>
                <v-radio-group 
                class="recommed-group"
                v-model="status"
                :rules="[(v) => !!v || 'Recommendation is required']"
                >
                  <v-row>
                    <div class="me-6">
                      <v-radio label="Agree" name="status" value="AGREE"></v-radio>
                    </div>
                    <div>
                      <v-radio label="Disagree" name="status" value="DISAGREE"></v-radio>
                    </div>
                  </v-row>
                </v-radio-group>
              </div>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <small>*All fields are mandatory</small> -->
            <v-spacer></v-spacer>
            <v-btn
              width="102px"
              height="48px"
              rounded
              outlined
              @click="demoRemarks = false"
              class="purple-button-light lightPurple purple-button-light-height-large pa-4"
              >Cancel</v-btn
            >
            <v-btn width="103px" height="48px" rounded-lg class="primary pa-4"
              @click="demoRemarkUpdate()"
              >Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogInterview" max-width="366px" persistent>
      <v-card fluid>
        <v-container fluid class="pa-0">
          <v-card-text class="text-center">
            <v-avatar color="#db44371f" size="90"
              ><v-icon size="65" color="#32CD32"
                >mdi-check-circle-outline</v-icon
              ></v-avatar
            >

            <p class="text-h5 pt-4 pb-0">
              You are procedding "{{ user_name }}" candidate for interview
            </p>
           
            <div class="d-flex justify-space-between" fluid>
              <v-btn
                class="black--text"
                color="#0000001a"
                large
                width="157px"
                rounded
                @click="dialogInterview = false"
                >CANCEL</v-btn
              >
              <v-btn
                class="primary"
                large
                width="157px"
                rounded
                @click="sendForInterview()"
                >YES</v-btn
              >
            </div>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>

    
    <v-data-table
      :headers="column"
      :items="recommendations"
      :search="search"
    >
      <template v-slot:[`item.actions`]="{item}">
        <div style="width: 140px" class="d-flex align-center">
          <a :href="`/#/users/profile/${item.id}`" class="d-flex align-center">
            <!-- <img 
              width="30px"
              class="pt-2 cursor"
              src="../assets/user.svg"
              /> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M18 18.86H17.24C16.44 18.86 15.68 19.17 15.12 19.73L13.41 21.42C12.63 22.19 11.36 22.19 10.58 21.42L8.87 19.73C8.31 19.17 7.54 18.86 6.75 18.86H6C4.34 18.86 3 17.53 3 15.89V4.97C3 3.33 4.34 2 6 2H18C19.66 2 21 3.33 21 4.97V15.88C21 17.52 19.66 18.86 18 18.86Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0691 8.95078C12.0291 8.95078 11.9691 8.95078 11.9191 8.95078C10.8691 8.91078 10.0391 8.06078 10.0391 7.00078C10.0391 5.92078 10.9091 5.05078 11.9891 5.05078C13.0691 5.05078 13.9391 5.93078 13.9391 7.00078C13.9491 8.06078 13.1191 8.92078 12.0691 8.95078Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.24945 11.9609C7.91945 12.8509 7.91945 14.3009 9.24945 15.1909C10.7595 16.2009 13.2395 16.2009 14.7495 15.1909C16.0795 14.3009 16.0795 12.8509 14.7495 11.9609C13.2395 10.9609 10.7695 10.9609 9.24945 11.9609Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
          <div v-if="item.is_show_icon && ((user_permission.recommended && user_permission.recommended.panel && user_permission.recommended.panel.demo_coordinator) || user.role_type == 'SUPER_ADMIN')" @click="() => {

            if(!item.isAlreadyPoll) {
              user_id = item.id;
              isAgreeText = 'AGREE';
              demoRemarks = true;
              name = `${user.first} ${user.last}`;
              remarks = null;
              status = null;
            } else {
              alreadyPollDialog = true
            }
              
            }"  class="ml-2 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M13.1502 15.3708C12.9302 15.0508 12.5401 14.8708 12.0901 14.8708H9.76015C9.61015 14.8708 9.46016 14.8108 9.37016 14.6908C9.27016 14.5708 9.23015 14.4208 9.25015 14.2508L9.54015 12.3908C9.66015 11.8408 9.29015 11.2109 8.74015 11.0209C8.22015 10.8309 7.62016 11.0909 7.37016 11.4609L4.98016 15.0108L4.91016 15.1308V20.1509L5.00015 20.2408L6.80016 21.6308C7.04016 21.8708 7.58015 22.0008 7.96015 22.0008H10.1702C10.9302 22.0008 11.6902 21.4308 11.8702 20.7308L13.2701 16.4808C13.4101 16.0708 13.3702 15.6808 13.1502 15.3708Z" fill="#2D2D2D"/>
              <path d="M3.82 13.3008H3.24C2.36 13.3008 2 13.6408 2 14.4808V20.1908C2 21.0308 2.36 21.3708 3.24 21.3708H3.82C4.7 21.3708 5.06 21.0308 5.06 20.1908V14.4808C5.06 13.6408 4.7 13.3008 3.82 13.3008Z" fill="#2D2D2D"/>
              <path opacity="0.4" d="M11.1997 8.60071C11.4197 8.92071 11.8097 9.10071 12.2597 9.10071H14.5897C14.7397 9.10071 14.8897 9.16076 14.9797 9.28076C15.0797 9.40076 15.1197 9.5507 15.0997 9.7207L14.8097 11.5807C14.6897 12.1307 15.0597 12.7607 15.6097 12.9507C16.1297 13.1407 16.7297 12.8807 16.9797 12.5107L19.3697 8.96075L19.4397 8.84076V3.82074L19.3497 3.73071L17.5497 2.34076C17.3097 2.10076 16.7697 1.9707 16.3897 1.9707H14.1797C13.4197 1.9707 12.6597 2.54072 12.4797 3.24072L11.0797 7.49072C10.9397 7.89072 10.9797 8.29071 11.1997 8.60071Z" fill="#2D2D2D"/>
              <path d="M20.5291 10.6716H21.1091C21.9891 10.6716 22.3491 10.3316 22.3491 9.49158V3.78162C22.3491 2.94162 21.9891 2.60156 21.1091 2.60156H20.5291C19.6491 2.60156 19.2891 2.94162 19.2891 3.78162V9.49158C19.2891 10.3316 19.6491 10.6716 20.5291 10.6716Z" fill="#2D2D2D"/>
            </svg>
          </div>
          <!-- <div v-if="item.is_show_icon && ((user_permission.recommended && user_permission.recommended.panel && user_permission.recommended.panel.update) || user.role_type == 'SUPER_ADMIN')" @click="() => {
              user_id = item.id;
              statusRecomm = true;
              isAgreeText = 'DISAGREE';
            }"  class="ml-2">
            <img 
              width="30px"
              class="pt-2 cursor"
              src="../assets/thumbs-down.svg"
              />
          </div> -->
          <div class="ml-2" v-if="((user_permission.recommended && user_permission.recommended.panel && user_permission.recommended.panel.update) || user.role_type == 'SUPER_ADMIN')">
            <img
             v-if="!item.is_interview_icon" 
              width="30px"
              class="pt-2 cursor"
              src="../assets/iv-grey.svg"
              />

              <img
              v-if="item.is_interview_icon" 
                width="30px"
                class="pt-2 cursor"
                src="../assets/iv-black.svg"

                @click="() => {
                  user_id = item.id;
                  user_name = item.name;
                  dialogInterview = true;
                  recommendations_id = item.r_id
                }" 
              />
          </div>
        </div>
      </template>
      <template v-slot:[`item.name`]="{item}">
        <div>{{ item.name }}</div>
        <div>{{ item.email }}</div>
      </template>
      <template v-slot:[`item.levels`]="{item}">
        <span
          ><v-chip class="mb-1" v-for="(data, index) in item.levels"
          :key="`chip-${index}`">{{ data }}</v-chip></span
        >
      </template>
      <template v-slot:[`item.ai_recommendation`]="{item}">
        <div v-if="item.ai_recommendation" class="success-reco">
          <div>Recommended</div>
          <div class="normaltext">For <strong>{{ item.ai_recommendation }}</strong></div>
        </div>
        <span v-else class="danger">Not Recommended</span>
      </template>

      
      <template v-slot:[`item.demo_score`]="{item}">
        <div style="width: 90px;" class="d-flex justify-content-center">
          {{ item.demo_score ? item.demo_score : ' - '  }}
        </div>
      </template>

      <template v-slot:[`item.status`]="{item}">
        <div style="width: 120px;" class="d-flex justify-content-center">
          <div class="white-with-text" :class="`${item.status.toLocaleLowerCase() == 'interview' ? 'interview' : (item.status.toLocaleLowerCase() == 'selected' ? 'can-selected' : item.actual_status.toLocaleLowerCase())}`">{{ item.status ? item.status : 'PENDING'  }}</div>
          <!-- <div class="white-with-text interview">{{ item.status && item.status.text ? item.status.text: 'NO'  }}</div>
          <div class="white-with-text can-selected">{{ item.status && item.status.text ? item.status.text: 'NO'  }}</div> -->
        </div>
      </template>
      
    </v-data-table>


    <v-dialog v-model="errorDialog" max-width="366px" persistent>
      <v-card>
        <v-container fluid class="pa-8">
          <v-card-text class="text-center">
            <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
            <p class="text-h5 pt-2 font-weight-medium">Error</p>
            <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
            <v-btn
              class="primary"
              large
              width="157px"
              rounded
              @click="errorDialog = false"
              >OK</v-btn
            >
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="successDialog" max-width="366px" persistent>
      <v-card>
        <v-container fluid class="pa-8">
          <v-card-text class="text-center">
            <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
            <p class="text-h5 py-4">Remarks Updated Successfully</p>
            <v-btn
              class="primary"
              large
              width="157px"
              rounded
              @click="successDialog = false"
              >OK</v-btn
            >
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="alreadyPollDialog" max-width="366px" persistent>
      <v-card>
        <v-container fluid class="pa-8">
          <v-card-text class="text-center">
            <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
            <p class="text-h5 py-4">You have already submitted your remarks</p>
            <v-btn
              class="primary"
              large
              width="157px"
              rounded
              @click="alreadyPollDialog = false"
              >OK</v-btn
            >
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    

  </v-container>
</template>
<script>
import "../styles.css";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
// import AssessmentController from "@/controllers/AssessmentController";
// import SkillsController from "@/controllers/SkillsController";
// import LevelController from "@/controllers/LevelController";
import RecommendationController from '@/controllers/RecommendationController'
import AuthService from "@/services/AuthService";

export default {
  mixins: [validationMixin],

  validations: {
    email: { required, email },
  },
  name: "RecommendationView",
  data() {
    return {
      isAgreeText: 'agree',
      user_id: 0,
      recommendations_id: 0,
      user_name: null,
      dialogInterview: false,
      user_type: 'TEACHER',
      statusRecomm: false,
      rules: {
        required: (value) => !!value || "Field is required",
      },
      successMessage:'New Assessment Created',
      selectedSkillSubjects: [],
      selectedSkillSubjectsMains: [],
      MainsDialog: false,
      selectedSubjects: [
        {
          subject_id: 0,
          no_of_questions: 0,
        },
      ],
      selectedSubjectsMains: [
        {
          subject_id: 0,
          no_of_questions: 0,
        },
      ],
      screenPanel:[],
      e1: 1,
      showUsers: true,
      cleared: [],
      inProgress: [],
      notCleared: [],
      yetToAttempt: [],
      search: null, 
      // name: "",
      scrBtnShow:false,
      screeningItem:0,
      mainItem:0,
      assessmentUsers: [],
      screeningMainsUser: "true",
      instructions: "",
      filterDialog: false,
      alreadyPollDialog: false,
      mainPanel:[],
      mainBtnShow:false,
      assessment_type: 'SCREENING',
      screeningConfiguration: {
        difficultyLevel: null,
        totalNumberQuestions: null,
        assessmentId: null,
        teachingLevel: null,
        searchSubjects: null,
        correctAnswerScore: 1,
        assessmentDuration: 0,
        negativeMarking: "NO",
        randomizeQuestions: "NO",
        shuffleOptions: "NO",
        displayCorrectAnswer: "NO",
        displayResult: "NO",
        selectedId: null,
        passingCriteria: 40,
        timeUpFirstReminder: null,
        timeUpLastReminder: null,
        assessmentType: "SCREENING",
        skillsFields: [{ skill_id: null, no_of_questions: 10 }],
        
      },
      breadItems: [
        {
          text: "Dashboard",
          disabled: false,
          to: "/",
        },
        {
          text: "Dashboard",
          disabled: false,
          to: "/",
        },
        {
          text: "Assessments",

          to: "/assessment",
        },
        {
          text: "Users in Mains Assessment",
          disabled: true,
          href: "",
        },
      ],
      publishData: {
          id: null,
          assessmentType: null,
        },
      skill_questions: [],
      skillQuestions: [],
      mainsQuestions: [],
      statusItems:[{key: 'PENDING', text: 'Pending'},
          {key: 'DISAGREE', text: 'Disagreed With AI'},
          {key: 'AGREE', text: 'Agreed With AI'}],
      mainsConfiguration: {
        difficultyLevel: null,
        totalNumberQuestions: null,
        teachingLevel: null,
        correctAnswerScore: 1,
        assessmentDuration: null,
        negativeMarking: "NO",
        randomizeQuestions: "NO",
        shuffleOptions: "NO",
        displayCorrectAnswer: "NO",
        displayResult: "NO",
        passingCriteria: 40,
        timeUpFirstReminder: null,
        timeUpLastReminder: null,
        assessmentType: "MAINS",
        skillsFields: [{ skill_id: null, no_of_questions: 10 }],
      },
      screeningUsers: [],
      errorMessage: "Failed",
      dialog: false,
      
      name: null,
      remarks: null,
      status: null,

      demoRemarks: false,
      subjectDialog: false,
      singleSelect: true,
      selected: [],
      successDialog: false,
      errorDialog: false,
      deleteDialog: false,
      publishDialog: false,
      subjectData: [],
      selectedSkillsFilter: [],
      headers: [
        { text: "Assessment Name", value: "name" },
        { text: "Created At", value: "created_at" },
        { text: "Instructions", value: "instructions" },
        { text: "Score Type", value: "score_type" },
        { text: "Actions", value: "actions" },
      ],
      breadMenu: ["menu1", "menu2", "menu3"],
      breadData: "menu1",
      column: [
        { text: "Full Name", value: "name" },
        { text: "Apply For Level", value: "levels" },
        { text: "Screening Score", value: "screening_score" },
        { text: "Main Score", value: "main_score" },
        { text: "Demo Score", value: "demo_score"},
        { text: "Interview Score", value: "interview_score" },
        { text: "AI Recom.", value: "ai_recommendation" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],
      assessments: [],
      recommendations: [],
      skillsList: [],
      levels: [],
      skillQuestionsIds: [],
      mainsQuestionsIds: [],
      editedSkill: null,
    };
  },
  computed: {
    user() {
      return AuthService.getLoggedUser();
    },
    user_permission() {
      return AuthService.getPermissions();
    },
  },
  methods: {
    truncate (string) {
      if(string) {
        if (string && string.length < 17) return string;
        const first_part = string.slice(0,4);
        const last_part = string.slice(-10);
        return first_part + '...' + last_part;
      } else {
        return string;
      }
      
    },
    async fetchRecommendations(type) {
      this.user_type = type;
      const response = await RecommendationController.getRecommendations(type);
      this.recommendations = response.data.data.rows;
      this.recommendations = this.recommendations.map(e => {
        console.log(e);
          let dat = {
            id: e.user_id,
            name: e.user.first_name,
            email: e.user.email ? e.user.email : ' - ',
            levels: e.levels,
            screening_score: e.screening_score ? `${e.screening_score} / ${e.screening_score_total}` : ' - ',
            main_score: e.mains_score ? `${e.mains_score} / ${e.mains_score_total}` : ' - ',
            demo_score: e.demo_score ? `${e.demo_score} / ${e.demo_score_total}` : ' - ',
            interview_score:  e.interview_score ? `${e.interview_score} / ${e.interview_score_total}` : ' - ',
            actual_status:  e.status,
            status:  e.status ? e.status.split('_').join(' ').toLocaleLowerCase() : 'PENDING',
            ai_recommendation: e.ai_recommendation,
            is_show_icon: e.demo_score > 0 && (e.status == 'DEMO_SUBMITTED' || e.status == 'INTERVIEW') ? true : false,
            is_interview_icon: (e.status == "DEMO_SUBMITTED" && e.recommendation_status == 'AGREE') || (e.demo_score > 0 && e.status == "DEMO_SUBMITTED" && e.recommendation_status == 'DISAGREE') ? true : false,
            r_id: e.id,
            isAlreadyPoll:  e.user_recommendation_polls.length > 0 ? (e.user_recommendation_polls.findIndex(ele => ele.admin_user_id == this.user.id) >= 0 ? true : false) : false,
          }
          let obj = {...dat};
          console.log(obj);
          return obj;
        })
      this.recommendations.reverse();
      console.log(this.recommendations);
    },
    
    async demoRemarkUpdate() {
      var response;
      if (this.$refs.form.validate()) {
        this.loading = true;
        // checking case for update/create
        
        response = await RecommendationController.setAIRecommendations({
        status: this.status,
        remarks: this.remarks
      }, this.user_id );
      
        if (response.data.success) {
          this.successDialog = true;
          this.name = null;
          this.remarks = null;
          this.status = null;
          this.demoRemarks = false;
          this.fetchRecommendations(this.user_type);
        } else {
          this.errorDialog = true;
          this.errorMessage = response.data.error;
        }
      }
    },

    onClickRecommendation(type) {
      this.fetchRecommendations(type);
    },
    async recommendedUpdate(status) {
      const response = await RecommendationController.setAIRecommendations({
        recommendation_status: status
      }, this.user_id );
      this.statusRecomm = false;
      console.log(response);
      this.fetchRecommendations(this.user_type);
    },
    async sendForInterview() {
      let findOj = this.recommendations.find(ele => ele.r_id == this.recommendations_id);
      let payload = {
        "recommended_level": findOj.levels[0],
      }
      const response = await RecommendationController.setToInterview(payload, this.user_id);
      console.log(response);
      window.location.href = "/#/interview/panel";
    }
  },

  created() {
    // this.getAssessmentDuration();
    // //this.getSubjects();
    // this.getSkills();
    // this.getLevels();
    this.fetchRecommendations('TEACHER');
    if (this.$route.params.cdialog == true) {
      this.dialog = true;
    }

    

    // this.getScreeningQuestions();
  },
};
</script>
<style>
.white-with-text {
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #2D2D2D;
  background-color: #F6F6F6;
  width: auto;
  height: auto;
  padding: 8px 8px 8px 8px;
  border-radius: 68px;
  justify: space-between;
  text-transform: capitalize;

}

.interview {
  background-color:  #CBD5E1;
  color: #475569;
}

.not_selected {
  background-color: #E5484D;
  color: #fff;
}
.demo_submitted {
  color: #6E56CF;
}
.can-selected {
  background-color: #03C988;
  color: #fff;
 
}
</style>